import type { Menu } from "~/types/menuData";
import { breakpointsTailwind } from "@vueuse/core";

export async function useMenu() {
  const menuStore = useMenuStore();
  const { data, menuState, menuElement, activeMenu } = storeToRefs(menuStore);
  const { initialize, refresh, toggle } = menuStore;

  const breakpoints = useBreakpoints(breakpointsTailwind);

  function hideMenuUpToLg() {
    if (breakpoints.smallerOrEqual("lg").value) {
      menuState.value = false;
    }
  }
  const { isAuthenticated, isWkSession } = await useAuth();

  await initialize();

  const { authenticationData } = await useAuthenticationData();
  const menuList = computed(() => {
    const resultList: Menu[] = [];
    if (authenticationData && authenticationData.value && data && data.value) {
      if (
        authenticationData.value.options.registrationEnabled &&
        ((!isAuthenticated.value &&
          !authenticationData.value.options.wkLoginOnly) ||
          isWkSession.value)
      ) {
        const files: Menu = {
          text: data.value.localization.registration,
          icon: "person-add",
          to: "/register",
          code: "register",
        };
        resultList.push(files);
      }
      if (isAuthenticated.value) {
        const userPage: Menu = {
          text: data.value.localization.userPage,
          icon: "person",
          to: "/user",
          code: "userPage",
        };
        resultList.push(userPage);
      }
      if (data.value.options.showRecrutationOffer) {
        const recrutationOffer: Menu = {
          text: data.value.localization.offerViewerLabel,
          icon: "location-on",
          to: "/offer/search",
          code: "offer",
        };
        resultList.push(recrutationOffer);
      }
      if (data.value.options.showNoRecrutationOffer) {
        const noRecrutationOffer: Menu = {
          text: data.value.localization.offerViewerNoRecrutationLabel,
          icon: "local-library",
          to: { path: "/offer/search", query: { noRecrutation: "true" } },
          code: "offerNoRecrutation",
        };
        resultList.push(noRecrutationOffer);
      }
      if (data.value.options.showRegionSchoolSearch) {
        const regionSchoolSearch: Menu = {
          text: data.value.localization.regionSchoolLabel,
          icon: "home-pin",
          to: "/region/search",
          code: "region",
        };
        resultList.push(regionSchoolSearch);
      }
      if (data.value.options.showPointsSimulator) {
        const pointsSimulator: Menu = {
          text: data.value.localization.pointsSimulatorLabel,
          icon: "table-view",
          to: "/pointsSimulator",
          code: "pointsSimulator",
        };
        resultList.push(pointsSimulator);
      }
      if (data.value.options.showFreePlaces) {
        const freePlaces: Menu = {
          text: data.value.localization.freePlacesLabel,
          icon: "mode-of-travel",
          to: {
            path: "/freePlaces/notCurrent/",
            query: {
              noRecrutation:
                data.value.options.showNoRecrutationOffer.toString(),
            },
          },
          code: "freePlaces",
        };
        resultList.push(freePlaces);
      }
      if (data.value.options.showCurrentFreePlaces) {
        const currentFreePlaces: Menu = {
          text: data.value.localization.freePlacesLabel,
          icon: "mode-of-travel",
          to: {
            path: "/freePlaces/current/",
            query: {
              noRecrutation:
                data.value.options.showNoRecrutationOffer.toString(),
            },
          },
          code: "freePlacesCurrent",
        };
        resultList.push(currentFreePlaces);
      }
      if (data.value.options.showMinimalPoints) {
        const minimalPoints: Menu = {
          text: data.value.localization.minimalPointsLabel,
          icon: "leaderboard",
          to: "/minimalPoints",
          code: "minimalPoints",
        };
        resultList.push(minimalPoints);
      }
      if (data.value.options.showStatistics) {
        const statistics: Menu = {
          text: data.value.localization.statisticsLabel,
          icon: "query-stats",
          to: "/statistics",
          code: "statistics",
        };
        resultList.push(statistics);
      }
      if (data.value.options.showRecrutationSummary) {
        const recrutationSummary: Menu = {
          text: data.value.localization.recrutationSummaryLabel,
          icon: "leaderboard",
          to: "/acceptedStatistics",
          code: "acceptedStatistics",
        };
        resultList.push(recrutationSummary);
      }
      if (data.value.options.showSchedule) {
        const schedule: Menu = {
          text: data.value.localization.scheduleLabel,
          icon: "calendar-month",
          to: "/schedule",
          code: "schedule",
        };
        resultList.push(schedule);
      }
      if (data.value.options.showFiles) {
        const files: Menu = {
          text: data.value.localization.filesLabel,
          icon: "download",
          to: "/instructionFiles",
          code: "files",
        };
        resultList.push(files);
      }
      if (data.value.dynamicMenus) {
        const entries = Object.entries(data.value.dynamicMenus);
        entries.sort(([, a], [, b]) => a.ordering - b.ordering);
        for (const [key, value] of entries) {
          const dynamic: Menu = {
            text: value.name,
            icon: value.publicIconClass,
            to: `/menu/${key.toString()}`,
            code: `dynamic_${key.toString()}`,
          };
          resultList.push(dynamic);
        }
      }
    }
    return resultList;
  });

  return {
    data,
    menuState,
    menuElement,
    menuList,
    activeMenu,
    initialize,
    refresh,
    toggle,
    hideMenuUpToLg,
  };
}
