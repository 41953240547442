import type { MenuCode } from "~/types/menuData";

export const useMenuStore = defineStore("menu", () => {
  const initialized = ref(false);
  const menuState = ref<boolean | null>();
  const menuElement = ref<HTMLElement | null>();

  const activeMenu = ref<MenuCode>();

  const { data, refresh } = useApi("/api/menuData", {
    immediate: false,
  });

  async function initialize() {
    if (!initialized.value) {
      initialized.value = true;
      await refresh();
    }
  }

  function isVisible() {
    if (menuElement.value) {
      return menuElement.value.offsetLeft >= 0;
    }
    return false;
  }

  function toggle() {
    menuState.value = !isVisible();
  }

  function $reset() {
    initialized.value = false;
    menuState.value = null;
    menuElement.value = null;
    activeMenu.value = undefined;
  }

  return {
    menuState,
    menuElement,
    data,
    activeMenu,
    initialize,
    refresh,
    isVisible,
    toggle,
    $reset,
  };
});
